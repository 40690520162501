import { PlusOutlined } from '@ant-design/icons';
import { Box } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import ContactStyle from '../../Style';
import { v4 as uuid4 } from 'uuid';
import MatrixTable from '../../../../components/MatrixTable';

export default function QuestionMatrix({ question, onSettingsChange }: any) {
  const classes = ContactStyle();

  // header
  // const onHeaderTitleChange = (dataIndex: any, value: any) => {
  //   const cloneColumns = _.cloneDeep(question.ratings.columns);
  //   cloneColumns.map((item: any) => {
  //     if (item.id === dataIndex) {
  //       item.title = value;
  //     }
  //   });

  //   onSettingsChange({ ...question, ratings: { ...question.ratings, columns: cloneColumns } });
  // };

  const onColumnValueChange = (dataIndex: any, value: any, field: any) => {
    const cloneColumns = _.cloneDeep(question.matrix.columns);
    cloneColumns.map((item: any) => {
      if (item.id === dataIndex) {
        item[field] = value;
      }
    });

    onSettingsChange({ ...question, matrix: { ...question.matrix, columns: cloneColumns } });
  };

  const onRowValueChange = (dataIndex: any, value: any, field: any) => {
    const cloneRows = _.cloneDeep(question.matrix.rows);
    cloneRows.map((item: any) => {
      if (item.id === dataIndex) {
        item[field] = value;
      }
    });

    onSettingsChange({ ...question, matrix: { ...question.matrix, rows: cloneRows } });
  };

  const showHideColumnSetting = (id: any, value: any) => {
    const cloneColumns = _.cloneDeep(question.matrix.columns);
    cloneColumns.map((item: any) => {
      let val = value;
      let className = '';
      if (value === 'none') {
        val = '';
        className = 'selectedTh';
      } else {
        val = 'none';
      }
      if (item.id === id) {
        item.expand = val;
        item.background = className;
      } else {
        item.expand = 'none';
        item.background = '';
      }
    });

    onSettingsChange({ ...question, matrix: { ...question.matrix, columns: cloneColumns } });
  };

  // const onHeaderSubTitleChange = (dataIndex: any, value: any) => {
  //   const cloneColumns = _.cloneDeep(question.ratings.columns);
  //   cloneColumns.map((item: any) => {
  //     if (item.id === dataIndex) {
  //       item.subTitle = value;
  //     }
  //   });
  //   onSettingsChange({ ...question, ratings: { ...question.ratings, columns: cloneColumns } });
  // };

  const onClickAddColumn = () => {
    const copyColumns = _.cloneDeep(question.matrix.columns);

    const newColumn = {
      label: 'Column',
      dataColumnPrefix: 'column',
      id: `${uuid4()}`,
      required: false,
      choices: [],
      expand: 'none',
    };
    newColumn.id = newColumn.id;
    copyColumns.push(newColumn);
    onSettingsChange({ ...question, matrix: { ...question.matrix, columns: copyColumns } });
  };

  const onClickDeleteColumn = (item: any) => {
    if (question.matrix.columns.length > 1) {
      const copyColumns = _.cloneDeep(question.matrix.columns);
      const index = copyColumns.findIndex((column: any) => column.id === item.key);
      copyColumns.splice(index, 1);
      onSettingsChange({ ...question, matrix: { ...question.matrix, columns: copyColumns } });
    }
  };

  // rows

  // const onRowTitleChange = (dataIndex: any, value: any) => {
  //   const cloneRow = _.cloneDeep(question.ratings.rows);
  //   cloneRow.map((item: any) => {
  //     if (item.id === dataIndex) {
  //       item.title = value;
  //     }
  //   });

  //   onSettingsChange({ ...question, ratings: { ...question.ratings, rows: cloneRow } });
  // };

  // const onRowSubTitleChange = (dataIndex: any, value: any) => {
  //   const cloneRow = _.cloneDeep(question.ratings.rows);
  //   cloneRow.map((item: any) => {
  //     if (item.id === dataIndex) {
  //       item.subTitle = value;
  //     }
  //   });
  //   onSettingsChange({ ...question, ratings: { ...question.ratings, rows: cloneRow } });
  // };

  const onClickAddRow = () => {
    const copyRows = _.cloneDeep(question.matrix.rows);
    const newColumn = {
      id: `${uuid4()}`,
      label: 'Row',
      dataColumnPrefix: 'default Row',
    };
    copyRows.push(newColumn);
    onSettingsChange({ ...question, matrix: { ...question.matrix, rows: copyRows } });
  };

  const onClickDeleteRow = (item: any) => {
    if (question.matrix.rows.length > 1) {
      const copyRows = _.cloneDeep(question.matrix.rows);
      const index = copyRows.findIndex((row: any) => row.id === item.id);
      copyRows.splice(index, 1);
      onSettingsChange({ ...question, matrix: { ...question.matrix, rows: copyRows } });
    }
  };

  // const TitleField = (text: any, record: any, index: any) => (
  //   <>
  //     <div>
  //       <HeaderTitle dataIndex={record?.key} title={record?.title} onHeaderChange={onRowTitleChange} />
  //       <HeaderTitle dataIndex={record?.key} title={record?.subTitle} onHeaderChange={onRowSubTitleChange} />
  //     </div>

  //     <Box className={classes.deleteIconPosition} id="popover-anchor1" onClick={() => onClickDeleteRow(record)}>
  //       <DeleteOutlined className={classes.deleteIcon} />
  //     </Box>
  //   </>
  // );

  // const formattingColumns = (columns: any[]) => {
  //   const cloneColumns = _.cloneDeep(columns);
  //   cloneColumns.map((item) => {
  //     if (item.id !== question.ratings.columns[0].id) {
  //       item.width = 100;
  //       item.align = 'center' as AlignType;
  //       item.dataIndex = item.id;
  //       item.key = item.id;
  //       item.render = RadioField;
  //       item.title = (
  //         <div>
  //           <div style={{ width: '80%' }}>
  //             <HeaderTitle dataIndex={item?.dataIndex} title={item?.title} onHeaderChange={onHeaderTitleChange} />
  //             <HeaderTitle dataIndex={item?.dataIndex} title={item?.subTitle} onHeaderChange={onHeaderSubTitleChange} />
  //           </div>

  //           <Box className={classes.deleteIconPosition} id="popover-anchor" onClick={() => onClickDeleteColumn(item)}>
  //             <DeleteOutlined className={classes.deleteIcon} />
  //           </Box>
  //         </div>
  //       );
  //     } else {
  //       item.render = TitleField;
  //       item.width = 100;
  //       item.align = 'center' as AlignType;
  //       item.dataIndex = item.id;
  //       item.key = item.id;
  //     }
  //   });
  //   return cloneColumns;
  // };

  // const formattingDataSource = (dataSource: any[]) => {
  //   const cloneDataSource = _.cloneDeep(dataSource);
  //   cloneDataSource.map((item) => {
  //     item.key = item.id;
  //   });
  //   return cloneDataSource;
  // };

  const optionValueChange = (index: any, columnKey: any, value: any, field: any) => {
    console.log('quesmat', question.matrix.columns);
    const columns = _.cloneDeep(question.matrix.columns);
    const cloneColumnsChoices = _.cloneDeep(question.matrix.columns[columnKey].choices);
    cloneColumnsChoices.map((item: any) => {
      if (item.id === index) {
        item[field] = value;
      }
    });
    columns[columnKey].choices = cloneColumnsChoices;

    onSettingsChange({ ...question, matrix: { ...question.matrix, columns: columns } });
  };

  const clearOptions = (id: any) => {
    const tempOptions = _.cloneDeep(question.matrix.columns);
    console.log({ tempOptions });
    const index = tempOptions.findIndex((row: any) => row.id === id);
    let choices = _.cloneDeep(question.matrix.columns[index].choices);
    choices = [];
    tempOptions[index].choices = choices;
    onSettingsChange({ ...question, matrix: { ...question.matrix, columns: tempOptions } });
  };

  const addOption = (id: any) => {
    const tempOptions = _.cloneDeep(question.matrix.columns);
    console.log({ tempOptions });
    const index = tempOptions.findIndex((row: any) => row.id === id);
    tempOptions[index].choices.push({ label: '', dataColumnName: '', id: `${uuid4()}` });
    onSettingsChange({ ...question, matrix: { ...question.matrix, columns: tempOptions } });
  };

  const removeOption = (choiceId: any, columnKey: any) => {
    const columns = _.cloneDeep(question.matrix.columns);
    const cloneColumnsChoices = _.cloneDeep(question.matrix.columns[columnKey].choices);
    const index = cloneColumnsChoices.findIndex((cohice: any) => cohice.id === choiceId);
    cloneColumnsChoices.splice(index, 1);

    columns[columnKey].choices = cloneColumnsChoices;
    onSettingsChange({ ...question, matrix: { ...question.matrix, columns: columns } });
  };

  const setCategoryId = (value: any, dataIndex: number) => {
    const cloneColumns = _.cloneDeep(question.matrix.columns);

    cloneColumns.map((item: any) => {
      if (item.id === dataIndex) {
        item.category = value;
        item.isSelect = true;
        item.isDefault = false;
      }
    });
    onSettingsChange({ ...question, matrix: { ...question.matrix, columns: cloneColumns } });
  };

  const onResponseTypeChange = (value: any, dataIndex: number) => {
    const cloneColumns = _.cloneDeep(question.matrix.columns);

    cloneColumns.map((item: any) => {
      if (item.id === dataIndex) {
        if (value === 'Select One' || value === 'Select Many') {
          item.responseType = value;
          item.isChoice = true;
        } else {
          item.responseType = value;
          item.isChoice = false;
        }
      }
    });
    onSettingsChange({ ...question, matrix: { ...question.matrix, columns: cloneColumns } });
  };

  const onSelectTypeChange = (value: any, dataIndex: number) => {
    const cloneColumns = _.cloneDeep(question.matrix.columns);

    cloneColumns.map((item: any) => {
      if (item.id === dataIndex) {
        item.isSelect = value;
      }
    });

    onSettingsChange({ ...question, matrix: { ...question.matrix, columns: cloneColumns } });
  };

  const onDefaultTypeChange = (value: any, dataIndex: number) => {
    const cloneColumns = _.cloneDeep(question.matrix.columns);

    cloneColumns.map((item: any) => {
      if (item.id === dataIndex) {
        item.isDefault = value;
        if (value === true) item.isSelect = false;
      }
    });
    console.log('vasddsd', cloneColumns);

    onSettingsChange({ ...question, matrix: { ...question.matrix, columns: cloneColumns } });
  };

  return (
    <div>
      <div style={{ width: '100%', padding: 20 }}>
        <div style={{ paddingRight: 25, position: 'relative' }}>
          <MatrixTable
            question={question}
            deleteColumn={onClickDeleteColumn}
            deleteRow={onClickDeleteRow}
            columnValueChange={onColumnValueChange}
            showHideColumnSetting={showHideColumnSetting}
            addOption={addOption}
            removeOption={removeOption}
            optionValueChange={optionValueChange}
            onResponseTypeChange={onResponseTypeChange}
            onRowValueChange={onRowValueChange}
            onSelectTypeChange={onSelectTypeChange}
            setCategoryId={setCategoryId}
            onDefaultTypeChange={onDefaultTypeChange}
            clearOptions={clearOptions}
          />
          {/* <Table pagination={false} dataSource={formattingDataSource(question.ratings?.rows)} columns={formattingColumns(question.ratings?.columns)} className={classes.table} /> */}
          <Box className={classes.addIconPosition} id="popover-anchor" onClick={onClickAddColumn}>
            <PlusOutlined />
          </Box>
          <Box className={classes.addRowIconPosition} id="popover-anchor" onClick={onClickAddRow}>
            <PlusOutlined /> Add Row
          </Box>
        </div>
      </div>
    </div>
  );
}
