import React, { SyntheticEvent, useState } from 'react';
import { Card } from 'antd';
import QuestionAddTool from '../../../components/Poper';
import { questionDefaultValue } from './Constants';
import Style from './Style';
import { IconButton } from '@mui/material';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

export default function InitialQuestion() {
  const [anchor, setAnchor] = useState<any>(null);
  const [anchorVisibility, setAnchorVisibility] = useState<boolean>(false);
  const classes = Style();
  const toggleAnchor = () => setAnchorVisibility(false);

  const openAnchor = (event: SyntheticEvent) => {
    setAnchor(event.currentTarget);
    setAnchorVisibility(!anchorVisibility);
  };

  return (
    <Card className={classes.cardRelative}>
      <div style={{ textAlign: 'center' }}>
        <p> This form is currently empty </p>
        <p> You can add questions, notes, prompts, or other fields by clicking on the + sign below </p>
      </div>
      {/* <PlusOutlined onClick={openAnchor} /> */}
      <IconButton aria-label="Add" color={anchorVisibility ? 'warning' : 'primary'} onClick={openAnchor}>
        {anchorVisibility ? <IndeterminateCheckBoxIcon fontSize="large" /> : <AddBoxIcon fontSize="large" />}
      </IconButton>
      <QuestionAddTool
        open={anchorVisibility}
        anchorEL={anchor}
        toggleAnchor={toggleAnchor}
        currentQustion={questionDefaultValue}
      />
    </Card>
  );
}
