import React, { useEffect, useState } from 'react';
import QuestionOption from './Options';
import { Grid } from '@material-ui/core';
import SkipLogic from './SkipLogic';
import ValidationCriteria from './ValidationCriteria';
import { useDispatch, useSelector } from 'react-redux';
import { closeSettingsTab, update } from '../../../redux/QuestionSlice';
// import Style from './Style';
import { isValid } from './settingsService';
import { Tabs } from 'antd';
import Rating from './Options/Rating';
import { MULTIPLE_SELECT, SELECT_ONE } from '../QuesTypes/Constants';
import Translate from './Options/Translate/';

import {
  RANGE_OPTION,
  CHOICE_OPTION,
  getQuesConfigByType,
  QUESTION_MATRIX_OPTION,
  QUESTION_OPTION,
  RANKING_OPTION,
  RATING_OPTION,
  SKIP_LOGIC,
  VALIDATION_CRITERIA,
  TRANSLATE,
} from './constants';
import Choice from './Options/Choice';
import QuestionMatrix from './Options/QuestionMatrix';
import Range from './Options/Range';

import { SaveOutlined, CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import RankingOption from './Options/Ranking';
// import Options from './Options';
// import { O_TRUNC } from 'constants';
import toast from 'react-hot-toast';
import { Box, Collapse } from '@mui/material';

export default function QuestionConfig() {
  const dispatch = useDispatch();
  const showSettingsTab = useSelector((state: any) => state.question.showSettingsTab);
  // const classes = Style();
  const selectedQuestion = useSelector((state: any) => state.question.selectedQuestion);
  const questions = useSelector((state: any) => state.question.questions);
  // const state = useSelector((state: any) => state.question);
  const [previousQuestion, setPreviousQuestion] = useState<any>(null);
  // const [msg, setMsg] = useState<any>({ severity: 'success', txt: '', show: false });
  const [question, setQuestion] = useState<any>(selectedQuestion);
  // console.log('questionn', question);
  /**
   * ------------------------------------------------------------------------------------------------------------------------
   * ----these 2 condition will apply if user forget to click the save button to save the configurtation.--------------------
   */
  /** condition 1 */
  if (
    previousQuestion !== null &&
    question.type !== '' &&
    selectedQuestion.type !== '' &&
    previousQuestion.path !== selectedQuestion.path
  ) {
    setPreviousQuestion(selectedQuestion);
    dispatch(update(question));
  }

  /** condition 2 */
  if (previousQuestion === null && selectedQuestion.type !== '') {
    setPreviousQuestion(selectedQuestion);
  }
  /**
   * ------------------------------------------------------------------------------------------------------------------------
   * ------------------------------------------------------------------------------------------------------------------------
   */

  useEffect(() => {
    setQuestion(selectedQuestion);
  }, [selectedQuestion]);

  const showMsg = () => {
    toast.success('Saved Successfully');
    // setMsg({ severity: 'success', show: true, txt: 'Saved Successfully' });
    dispatch(closeSettingsTab({}));
  };

  const showErrMsg = (txt: string) => {
    toast.error(txt);
    // setMsg({ severity: 'error', show: true, txt });
    // setTimeout(() => {
    //   setMsg({ ...msg, show: false, txt: '' });
    // }, 2000);
  };

  const isChanged = (question: any) => {
    if (question.type === SELECT_ONE || question.type === MULTIPLE_SELECT) {
      const prev_ques = questions.filter((ques: any) => ques.id === question.id)[0];
      if (prev_ques) {
        if (
          (prev_ques.choice && question.choice && prev_ques.choice.isDefault !== question.choice.isDefault) ||
          (prev_ques.choice.id && prev_ques.choice.id !== question.choice.id)
        ) {
          return true;
        } else if (question.choice.isDefault === true) {
          containsOption(question);
        }
      }
      // else if (prev_ques.choice.isDefault === true) {
      //   if (prev_ques.choice.options && !question.choice.options) return true;

      //   //  else if (prev_ques.choice.options){
      //   //   const find_option = prev_ques.choice.options.filter((opt:any)=>opt.uuid === question.choice.)
      //   //  }

      //   if (prev_ques.choice.options.length > 0) {
      //     for (let i = 0; i < prev_ques.choice.options.length; i = i + 1) {
      //       if (
      //         prev_ques.choice.options[i].option !== question.choice.options[i].option ||
      //         prev_ques.choice.options[i].value !== question.choice.options[i].value
      //       ) {
      //         //   console.log('here');
      //         return true;
      //       }
      //     }
      //   }
      // }
    }
    return false;
  };

  const updateQuestion = () => {
    const valid: any = isValid(question);
    if (isChanged(question)) {
      deleteSkipLogic(question.id);
    }
    //  updateQuestionSkipLogic(question);
    if (valid.verdict) {
      dispatch(update(question));
      setPreviousQuestion(null);
      showMsg();
    } else {
      showErrMsg(valid.msg);
    }
  };

  const containsOption = (question: any) => {
    questions.map((ques: any) => {
      const queue = [];
      queue.push(ques);

      while (queue.length > 0) {
        const elem = queue.shift();
        if (elem.type === 'Group Question') {
          elem.questions.map((q: any) => {
            queue.push(q);
          });
        } else {
          const tempLogList = JSON.parse(JSON.stringify(elem.logicList));

          tempLogList.map((list: any, i: any) => {
            if (list.question.id === question.id) {
              list.question = question;
              // const tempList = JSON.parse(JSON.stringify(list));
              if (list.value) {
                const opt = question.choice.options.filter((ques: any) => ques.uuid === list.value.uuid);
                if (opt.length === 0) {
                  tempLogList.splice(i, 1);
                } else {
                  for (let i = 0; i < question.choice.options.length; i++) {
                    if (question.choice.options[i].uuid === list.value.uuid) {
                      list.value = question.choice.options[i];
                      console.log('here');
                    }
                  }
                  //  tempLogList[i] = tempList;
                }
              }

              // console.log('list', list);
              // const opt = question.choice.options.filter((ques: any) => ques.value === list.value.value);
            }
          });
          let temp = { ...elem };
          temp = { ...temp, logicList: tempLogList };

          dispatch(update(temp));

          //  console.log('tempLogList', tempLogList);
        }
      }
    });
  };

  const cancel = () => dispatch(closeSettingsTab({}));

  const onEvent = (key: string, value: any) => {
    setQuestion((prevState: any) => ({
      ...prevState,
      [`${key}`]: value,
    }));
  };

  // bfs to traverse the tree
  const deleteSkipLogic = (questionId: any) => {
    questions.map((ques: any) => {
      const queue = [];
      queue.push(ques);

      while (queue.length > 0) {
        const elem = queue.shift();
        if (elem.type === 'Group Question') {
          elem.questions.map((q: any) => {
            queue.push(q);
          });
        } else {
          const logList = elem.logicList?.filter((l: any) => l.question.id !== questionId);
          if (elem.logicList) {
            let temp = { ...elem };
            temp = { ...temp, logicList: logList };
            dispatch(update(temp));
          }
        }
      }
    });
  };

  const updateQuestionSkipLogic = (question: any) => {
    questions.map((ques: any) => {
      const queue = [];
      queue.push(ques);

      while (queue.length > 0) {
        const elem = queue.shift();
        if (elem.type === 'Group Question') {
          elem.questions.map((q: any) => {
            queue.push(q);
          });
        } else {
          //  console.log('elemss', elem);
          const tempLogList = JSON.parse(JSON.stringify(elem.logicList));

          tempLogList.map((list: any) => {
            if (list.question.id === question.id) {
              list.question = question;
            }
          });
          let temp = { ...elem };
          temp = { ...temp, logicList: tempLogList };
          dispatch(update(temp));

          //  console.log('tempLogList', tempLogList);
        }
      }
    });
  };

  const configuration = (config: any) => {
    switch (config) {
      case QUESTION_OPTION:
        return <QuestionOption question={question} onChange={(ques: any) => setQuestion(ques)} />;

      case SKIP_LOGIC:
        return <SkipLogic question={question} onLogicChange={(ques: any) => setQuestion(ques)} onEvent={onEvent} />;

      case VALIDATION_CRITERIA:
        return <ValidationCriteria question={question} onChange={(ques: any) => setQuestion(ques)} onEvent={onEvent} />;

      case RATING_OPTION:
        return <Rating question={question} onSettingsChange={(ques: any) => setQuestion(ques)} />;

      case CHOICE_OPTION:
        return (
          <Choice question={question} onChange={(ques: any) => setQuestion(ques)} deleteSkipLogic={deleteSkipLogic} />
        );

      case RANKING_OPTION:
        return <RankingOption question={question} onChange={(ques: any) => setQuestion(ques)} />;

      case QUESTION_MATRIX_OPTION:
        return <QuestionMatrix question={question} onSettingsChange={(ques: any) => setQuestion(ques)} />;

      case RANGE_OPTION:
        return <Range question={question} onChange={(ques: any) => setQuestion(ques)} />;

      case TRANSLATE:
        return <Translate question={question} onChange={(ques: any) => setQuestion(ques)} />;

      default:
        return null;
    }
  };

  const questionConfigs = () => {
    return getQuesConfigByType(question.type).map((name: any) => ({
      name,
      element: configuration(name),
    }));
  };

  return (
    <Collapse in={showSettingsTab}>
      <Box>
        <Tabs
          tabPosition="left"
          items={questionConfigs().map((elm: any, i: any) => {
            const id = String(i + 1);
            return {
              label: elm.name,
              key: id,
              children: elm.element,
            };
          })}
          style={{ backgroundColor: 'white' }}
        />
        <Grid container justifyContent="center" style={{ padding: 30 }}>
          <Button icon={<CloseOutlined />} danger onClick={cancel} style={{ marginRight: 20 }}>
            {' '}
            Cancel
          </Button>
          <Button icon={<SaveOutlined />} type="primary" onClick={updateQuestion}>
            {' '}
            Submit
          </Button>
        </Grid>
      </Box>
    </Collapse>
  );
}
