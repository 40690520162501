import React from 'react';
import { Checkbox } from '@material-ui/core';
import { labelCol } from '../Style';
import {
  CALCULATE,
  FILE,
  MULTIPLE_SELECT,
  PHOTO,
  QUESTION_MATRIX,
  RATING,
  SELECT_ONE,
} from '../../QuesTypes/Constants';
import { Col, Form, Input, Radio, Select } from 'antd';
import { YES, NO, CUSTOM_LOGIC } from '../constants';
import { useSelector } from 'react-redux';

export const NameElement = ({ question, onQuestionChange }: any) => {
  const notApplicableQuestions: any = [];
  const found = notApplicableQuestions.find((type: any) => type == question.type);
  return !found ? (
    <Col span={20}>
      <Form.Item labelAlign="left" labelCol={labelCol} label="Data column name:" rules={[{ required: true }]}>
        <Input name="name" value={question.name} onChange={onQuestionChange} />
      </Form.Item>
    </Col>
  ) : null;
};

export const KoboScoreChoices = ({ question, onQuestionChange }: any) => {
  const applicableQuestions: any = [RATING];
  const found = applicableQuestions.find((type: any) => type == question.type);
  return found ? (
    <Col span={20}>
      <Form.Item labelAlign="left" labelCol={labelCol} label="Kobo Score Choices:" rules={[{ required: true }]}>
        <Input name="koboScoreChoices" value={question.koboScoreChoices} onChange={onQuestionChange} />
      </Form.Item>
    </Col>
  ) : null;
};

export const AcceptedFileElement = ({ question, onQuestionChange }: any) => {
  const applicableQuestions: any = [FILE];
  const found = applicableQuestions.find((type: any) => type == question.type);
  return found ? (
    <Col span={20}>
      <Form.Item labelAlign="left" labelCol={labelCol} label="Accepted Files:" rules={[{ required: true }]}>
        <Input name="acceptedFile" value={question.acceptedFile} onChange={onQuestionChange} />
      </Form.Item>
    </Col>
  ) : null;
};

export const LabelElement = ({ question, onNestedQuestionChange, code, isDefault }: any) => {
  const notApplicableQuestions = [CALCULATE, QUESTION_MATRIX];
  const found = notApplicableQuestions.find((type: any) => type == question.type);
  return !found ? (
    <Col span={20}>
      <Form.Item
        labelAlign="left"
        labelCol={labelCol}
        label={isDefault ? `Label (${code}) - default:` : `Label (${code}):`}
        rules={[{ required: true }]}
      >
        <Input
          value={question.label[code] ? question.label[code] : ''}
          onChange={(e) => {
            // console.log('question', question, question.label[`$code}`]);
            onNestedQuestionChange(e, 'label', code);
          }}
        />
      </Form.Item>
    </Col>
  ) : null;
};

export const ErrorMessageElement = ({ question, onNestedQuestionChange, code, isDefault }: any) => {
  return (
    <Col span={20}>
      <Form.Item
        labelAlign="left"
        labelCol={labelCol}
        label={isDefault ? `Error Message (${code}) - default:` : `Error Message (${code}):`}
        rules={[{ required: true }]}
      >
        <Input
          value={question.errorMsg[code] ? question.errorMsg[code] : ''}
          onChange={(e) => {
            // console.log('question', question, question.label[`$code}`]);
            onNestedQuestionChange(e, 'errorMsg', code);
          }}
        />
      </Form.Item>
    </Col>
  );
};

export const CalculationElement = ({ question, onQuestionChange }: any) => {
  const applicableQuestions = [CALCULATE];
  const found = applicableQuestions.find((type: any) => type == question.type);
  return found ? (
    <Col span={20}>
      <Form.Item labelAlign="left" labelCol={labelCol} label="Calculation:" rules={[{ required: true }]}>
        <Input name="calculation" onChange={onQuestionChange} value={question.calculation} />
      </Form.Item>
    </Col>
  ) : null;
};

export const GuidenceHintElement = ({ question, onQuestionChange, code, isDefault }: any) => {
  const notApplicableQuestions: any = [QUESTION_MATRIX];
  const found = notApplicableQuestions.find((type: any) => type == question.type);
  return !found ? (
    <Col span={20}>
      <Form.Item
        labelAlign="left"
        labelCol={labelCol}
        label={isDefault ? `Guidance Hint (${code}) - default:` : `Guidance Hint (${code}):`}
        rules={[{ required: true }]}
      >
        <Input
          name="hint"
          value={question.hint[code] ? question.hint[code] : ''}
          onChange={(e: any) => onQuestionChange(e, 'hint', code)}
        />
      </Form.Item>
    </Col>
  ) : null;
};

export const MandatoryResponse = ({ question, onQuestionChange }: any) => {
  const notApplicableQuestions: any = [QUESTION_MATRIX];
  const found = notApplicableQuestions.find((type: any) => type == question.type);
  return !found ? (
    <Col span={20}>
      <Form.Item labelAlign="left" labelCol={labelCol} label="Mandatory Response:" rules={[{ required: true }]}>
        <Radio.Group name="mandatory" onChange={onQuestionChange} value={question.mandatory}>
          <Radio value={YES}>Yes</Radio>
          <Radio value={NO}>No</Radio>
          <Radio value={CUSTOM_LOGIC}>Custom Logic</Radio>
        </Radio.Group>
      </Form.Item>
    </Col>
  ) : null;
};

export const DefaultResponse = ({ question, onQuestionChange }: any) => {
  const notApplicableQuestions: any = [QUESTION_MATRIX];
  const found = notApplicableQuestions.find((type: any) => type == question.type);
  return !found ? (
    <Col span={20}>
      <Form.Item labelAlign="left" labelCol={labelCol} label="Default Response:" rules={[{ required: true }]}>
        <Input name="defaultResponse" value={question.defaultResponse} onChange={onQuestionChange} />
      </Form.Item>
    </Col>
  ) : null;
};

export const Appearance = ({ question, onQuestionSelect }: any) => {
  const notApplicableQuestions: any = [QUESTION_MATRIX];
  const found = notApplicableQuestions.find((type: any) => type == question.type);
  const { appearanceOptions } = useSelector((state: any) => state.form.masterData);
  return !found ? (
    <Col span={20}>
      <Form.Item labelAlign="left" labelCol={labelCol} label="Appearance" rules={[{ required: true }]}>
        <Select value={question.appearance} onChange={(value) => onQuestionSelect('appearance', value)} allowClear>
          {appearanceOptions &&
            appearanceOptions.map((data: any, i: any) => (
              <Select.Option key={i} value={data.value}>
                {' '}
                {data.label}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
    </Col>
  ) : null;
};

export const HXLElement = ({ question, onQuestionChange, onQuestionSelect }: any) => {
  const notApplicableQuestions: any = [QUESTION_MATRIX];
  const found = notApplicableQuestions.find((type: any) => type == question.type);
  const { hxlTags } = useSelector((state: any) => state.form.masterData);
  const selectBefore = (
    <Select defaultValue="#tags" className="select-before" onChange={(value: any) => onQuestionSelect('tags', value)}>
      {hxlTags &&
        hxlTags.map((hxl: any, index: any) => (
          <Select.Option value={hxl.value} key={index}>
            {`#${hxl.label}`}{' '}
          </Select.Option>
        ))}
    </Select>
  );
  return !found ? (
    <Col span={20}>
      <Form.Item labelAlign="left" labelCol={labelCol} label="HXL:" rules={[{ required: true }]}>
        <Input addonBefore={selectBefore} name="tagValue" onChange={onQuestionChange} />
      </Form.Item>
    </Col>
  ) : null;
};

export const Parameters = ({ question, onChange }: any) => {
  const applicableQuestions: any = [SELECT_ONE, MULTIPLE_SELECT, PHOTO];
  const found = applicableQuestions.find((type: any) => type == question.type);
  return found ? (
    <Col span={20}>
      <Form.Item labelAlign="left" labelCol={labelCol} label="Parameters:" rules={[{ required: true }]}>
        <Checkbox
          name="parameters"
          checked={question.parameters}
          onChange={() => onChange({ ...question, parameters: !question.parameters })}
        />
      </Form.Item>
    </Col>
  ) : null;
};

export const SeedElement = ({ question, onQuestionChange }: any) => {
  const applicableQuestions: any = [SELECT_ONE, MULTIPLE_SELECT];
  const found = applicableQuestions.find((type: any) => type == question.type);
  return found ? (
    <Col span={20}>
      <Form.Item labelAlign="left" labelCol={labelCol} label="Seed:" rules={[{ required: true }]}>
        <Input name="seed" value={question.seed} onChange={onQuestionChange} />
      </Form.Item>
    </Col>
  ) : null;
};

export const ListName = ({ question, onQuestionChange }: any) => {
  const applicableQuestions = [SELECT_ONE, MULTIPLE_SELECT];
  const found = applicableQuestions.find((type: any) => type == question.type);
  return found ? (
    <Col span={20}>
      <Form.Item labelAlign="left" labelCol={labelCol} label="List Name:" rules={[{ required: true }]}>
        <Input name="list_name" value={question.list_name} onChange={onQuestionChange} />
      </Form.Item>
    </Col>
  ) : null;
};

export const MatrixAppearance = ({ question, onChange }: any) => {
  const applicableQuestions: any = [QUESTION_MATRIX];
  const found = applicableQuestions.find((type: any) => type == question.type);
  return found ? (
    <Col span="20">
      <Form.Item labelAlign="left" labelCol={labelCol} label="Appearance:">
        <Checkbox
          name="appearance"
          checked={question.appearance}
          onChange={() => onChange({ ...question, appearance: !question.appearance })}
        />
        Show all questions in this group on the same screen
      </Form.Item>
    </Col>
  ) : null;
};

export const Repeat = ({ question, onChange }: any) => {
  const applicableQuestions: any = [QUESTION_MATRIX];
  const found = applicableQuestions.find((type: any) => type == question.type);
  return found ? (
    <Col span="20">
      <Form.Item labelAlign="left" labelCol={labelCol} label="Repeat:">
        <Checkbox
          name="repeat"
          checked={question.repeat}
          onChange={() => onChange({ ...question, repeat: !question.repeat })}
        />
        Repeat this group if necessary
      </Form.Item>
    </Col>
  ) : null;
};
