import { questionDefaultValue } from '../Questions/Constants';
import { MULTIPLE_SELECT, RANKING, SELECT_ONE, QUESTION_MATRIX, RANGE } from './Constants';
import { v4 } from 'uuid';

const getOptions = (defaultLanguage: any, selectedLanguage: any, optionValue: any) => {
  const opt: any = {};
  opt[defaultLanguage.code] = optionValue;

  selectedLanguage.map((lang: any) => {
    opt[lang.code] = optionValue;
  });
  return opt;
};

export const getDefaultValueByQuestionType = (type: any, defaultLanguage: any, selectedLanguage: any) => {
  switch (type) {
    case MULTIPLE_SELECT:
      return {
        ...questionDefaultValue,
        choice: {
          options: [
            {
              option: getOptions(defaultLanguage, selectedLanguage, 'option 1'),
              value: 1,
              uuid: v4(),
            },
            {
              option: getOptions(defaultLanguage, selectedLanguage, 'option 2'),
              value: 2,
              uuid: v4(),
            },
          ],
          isDefault: true,
        },
      };

    case SELECT_ONE:
      return {
        ...questionDefaultValue,
        choice: {
          options: [
            {
              option: getOptions(defaultLanguage, selectedLanguage, 'option 1'),
              value: 1,
              uuid: v4(),
            },
            {
              option: getOptions(defaultLanguage, selectedLanguage, 'option 2'),
              value: 2,
              uuid: v4(),
            },
          ],
          isDefault: true,
        },
      };

    case RANKING:
      return {
        ...questionDefaultValue,
        ranks: { items: [{ label: 'item 1 ', value: '1' }], choices: [{ label: 'choice 1 ', value: '1' }] },
      };
    case QUESTION_MATRIX:
      return {
        ...questionDefaultValue,
        matrix: {
          columns: [
            {
              label: 'Column1',
              dataColumnPrefix: 'column',
              id: 1,
              responseType: 'Text',
              isChoice: false,
              required: true,
              choices: [],
              expand: 'none',
              background: '',
            },
            {
              label: 'Column',
              dataColumnPrefix: 'column',
              id: '2',
              responseType: 'Number',
              required: true,
              choices: [],
              expand: 'none',
            },
          ],
          rows: [
            {
              id: '1',
              label: 'Row1',
              dataColumnPrefix: 'default Row',
            },
          ],
        },
      };
    case RANGE:
      return {
        ...questionDefaultValue,
        range: { start: 1, end: 10, step: 1 },
      };
    default:
      return questionDefaultValue;
  }
};
