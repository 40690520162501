import { CloseOutlined, EyeOutlined, BarChartOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SaveOutlined } from '@ant-design/icons';
import { Modal, Button } from 'antd';
import queryString from 'query-string';

import Icons from '../../Icons';
import subHeaderStyle from '../SortableContainer/Style';
import LayoutSettings from './LayoutSettings';
import QuestionBank from '../QuestionBank';
import axiosServices from '../../helpers/axiosService';
import { saveURL } from '../../configs/ServerUrl';
import { createGroup } from '../../redux/QuestionSlice';
import { setForm } from '../../redux/FormSlice';
import { checkFormValidation } from '../../helpers/utils';
import LanguageSelection from '../LanguageSelection';
import { IconButton, Tooltip } from '@mui/material';
import ClearTwoToneIcon from '@material-ui/icons/ClearTwoTone';
import toast from 'react-hot-toast';

export default function Header() {
  const [open, setOpen] = useState(false);
  const [openQuestionBank, setOpenQuestionBank] = useState(false);
  const [openLanguageSelection, setOpenLanguageSelection] = useState(false);

  const classes = subHeaderStyle();
  const dispatch = useDispatch();
  const question = useSelector((state: any) => state.question);
  const { multiselection } = question;

  const form = useSelector((state: any) => state.form);
  const _createGroup = () => {
    dispatch(createGroup(''));
  };

  const onChange = (key: any, value: any) => {
    dispatch(setForm({ [key]: value }));
  };

  const save = async (e: any) => {
    const urlParams = queryString.parse(window.location.search);

    const validStatus = checkFormValidation(form);
    if (!validStatus.valid && validStatus.msg) {
      // notification['error']({
      //   message: 'Error',
      //   description: validStatus.msg,
      // });
      toast.error(validStatus.msg);
      return;
    }

    // const householdRegistration = {
    //   idString: 'HouseHoldRegistration',
    //   title: 'Household Registration',
    //   description: 'Household Registration',
    //   countryId: '1',
    //   sectorId: '1',
    // };

    const payload = {
      questions: question.questions,
      form,
      surveyId: urlParams.id,
    };

    try {
      const response = await axiosServices.post(`${saveURL}`, payload);
      if (response.status === 200) {
        // notification['success']({
        //   message: 'Success',
        //   description: 'Form saved successfully !',
        // });
        toast.success('Form saved successfully !!!');
      }
    } catch (ex) {
      // notification['error']({
      //   message: 'Error',
      //   description: 'Can not save the form',
      // });
      toast.error('Can not save the form');
    }

    // try {
    //   const response = await axiosServices.post(`${saveURL}`, payload);
    //   notification['success']({
    //     message: 'Success',
    //     description: 'Form saved successfully !',
    //   });
    // } catch (ex) {
    //   notification['error']({
    //     message: 'Error',
    //     description: 'Can not save the form',
    //   });
    // }
  };

  return (
    <>
      <Box className={classes.formHeader}>
        <Box>
          <Box className={classes.formName}>
            <input
              className={classes.formTextEditable}
              placeholder="Form Name"
              type="text"
              value={form.formName}
              onChange={(e: any) => onChange('formName', e.target.value)}
            />
          </Box>
          <Box className={classes.formTitle}>
            <input
              className={classes.formTextEditable}
              placeholder="Form Title"
              type="text"
              value={form.formTitle}
              onChange={(e: any) => onChange('formTitle', e.target.value)}
            />
          </Box>
        </Box>
        <Box className={classes.formAction}>
          <Button icon={<SaveOutlined />} type="primary" onClick={save}>
            Save
          </Button>
          <IconButton color="error">
            <ClearTwoToneIcon />
          </IconButton>
        </Box>
      </Box>

      <Box className={classes.action}>
        <Box>
          <Tooltip title="Preview" placement="bottom-end" arrow>
            <IconButton>
              <EyeOutlined />
            </IconButton>
          </Tooltip>
          <IconButton>
            <Icons.FormatLineSpacing width={22} height={22} />
          </IconButton>
          <Tooltip title="Group" placement="bottom-end" arrow>
            <IconButton
              onClick={_createGroup}
              disabled={!multiselection.onGoing}
              style={{ backgroundColor: !multiselection.onGoing ? 'transparent' : 'lightblue' }}
              className={multiselection.onGoing && classes.shake}
            >
              <Icons.Copy width={22} height={22} />
            </IconButton>
          </Tooltip>
          <IconButton>
            <Icons.SchemaFill width={22} height={22} />
          </IconButton>
        </Box>
        <Box className={classes.layoutSettings}>
          <Icons.Line />
          <IconButton onClick={() => setOpenQuestionBank(true)}>
            <BarChartOutlined />
          </IconButton>
          <Box className={classes.formTitle}>Add from library</Box>
          <span style={{ paddingRight: 15 }}></span>
          <Icons.Line />
          <IconButton onClick={() => setOpen(true)}>
            <Icons.TuneFill width={22} height={22} />
          </IconButton>
          <Box className={classes.formTitle}>Layout & settings</Box>
          <span style={{ paddingRight: 15 }}></span>
          <Icons.Line />
          <IconButton onClick={() => setOpenLanguageSelection(true)}>
            <UnorderedListOutlined />
          </IconButton>
          <Box className={classes.formTitle}>Select Language</Box>
          <span style={{ paddingRight: 15 }}></span>
        </Box>
      </Box>
      <Modal
        title="Layout & Settings"
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={1000}
        footer={null}
      >
        <LayoutSettings close={() => setOpen(false)} />
      </Modal>
      <Modal
        title="Question Bank"
        centered
        open={openQuestionBank}
        onOk={() => setOpenQuestionBank(false)}
        onCancel={() => setOpenQuestionBank(false)}
        width={1000}
        footer={null}
      >
        <QuestionBank close={() => setOpenQuestionBank(false)} />
      </Modal>

      <Modal
        title="Question Bank"
        centered
        open={openLanguageSelection}
        onOk={() => setOpenLanguageSelection(false)}
        onCancel={() => setOpenLanguageSelection(false)}
        width={1000}
        footer={null}
      >
        <LanguageSelection close={() => setOpenLanguageSelection(false)} />
      </Modal>
    </>
  );
}
