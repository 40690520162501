import _ from 'lodash';
import { IForm } from '../types/state';

/** Interface for an object that is allowed to have any property */
export interface FlexObject {
  [key: string]: any;
}

export const deepClone = (ob: any) => {
  if (Object.keys(ob).length > 0) {
    return JSON.parse(JSON.stringify(ob));
  } else return {};
};

export const checkFormValidation = (form: IForm) => {
  console.log(form);
  if (_.isEmpty(form.formTitle)) return { valid: false, msg: 'Form title is required' };
  if (_.isEmpty(form.formName)) return { valid: false, msg: 'Form name is required' };
  if (_.isEmpty(form.formStyle)) return { valid: false, msg: 'Form style is required' };
  return { valid: true };
};
