import React, { SyntheticEvent, useState } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Style, { getImageByType } from './Style';
import { Box, Tooltip, Typography } from '@material-ui/core';
import QuestionAddTool from '../../../components/Poper';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToMultiSelection,
  selectQuestion,
  deleteQuestion,
  closeSettingsTab,
  add,
} from '../../../redux/QuestionSlice';
import QuestionConfig from '../Settings';
import { activeStyle, inActiveStyle } from './Constants';
import { DeleteOutlined, FileAddOutlined, PlusOutlined, SettingOutlined, CopyOutlined } from '@ant-design/icons';
import { addToQuesBank } from '../../../redux/QuesBankSlice';
import { IQuestion, MainState } from '../../../types/state';
import { v4 as uuidv4 } from 'uuid';
import Slide from '@mui/material/Slide';
import toast from 'react-hot-toast';

type QuestionItemPropsType = {
  questionItem: IQuestion;
};

export default function QuestionItem({ questionItem }: QuestionItemPropsType) {
  const classes = Style();
  const [anchorVisibility, setAnchorVisibility] = useState<boolean>(false);
  const [anchor, setAnchor] = useState<any>(null);
  const [iconHover, setIconHover] = useState({ bg: 'white', text: '' });
  const dispatch = useDispatch();
  const toggleAnchor = () => setAnchorVisibility(false);
  const questions: IQuestion[] = useSelector((state: MainState) => state.question.questions);
  const selectedQuestion: IQuestion = useSelector((state: any) => state.question.selectedQuestion);
  const multiselection = useSelector((state: any) => state.question.multiselection);

  const openAnchor = (event: SyntheticEvent) => {
    setAnchor(event.currentTarget);
    setAnchorVisibility(true);
  };

  const setSelectedQuestion = (e: any) => {
    if (e.ctrlKey) {
      dispatch(addToMultiSelection(questionItem.path));
    } else {
      dispatch(selectQuestion(questionItem));
    }
  };

  const isActive = () => {
    if (multiselection.onGoing) {
      return multiselection.pathList.includes(questionItem.path);
    }
    return selectedQuestion?.id === questionItem?.id;
  };

  const onDelete = (e: SyntheticEvent) => {
    e.stopPropagation();
    const yes = confirm(`Are you sure you want to delete ? \n ${questionItem.name}`);
    if (yes) {
      dispatch(deleteQuestion(questionItem));
      dispatch(closeSettingsTab({}));
    }
  };

  const addToQuestionBank = (e: SyntheticEvent) => {
    e.stopPropagation();
    dispatch(addToQuesBank(questionItem));
    toast.success('Question has been added');
  };

  const duplicateQuestion = (event: React.SyntheticEvent, id: string) => {
    event.stopPropagation();
    const question = questions.find((ques) => ques.id === id);

    if (question) {
      const duplicatedQuestion = {
        ...question, // Shallow copy of selectedQuestion
        id: uuidv4(), // Override the id property
      };
      dispatch(add(duplicatedQuestion));
    }
  };

  const containerRef = React.useRef<HTMLElement>(null);

  return (
    <Slide in container={containerRef.current}>
      <Box className={classes.cardStyle}>
        <Box sx={{ position: 'relative' }}>
          <Box className={classes.addBoxIconPosition} id="popover-anchor" onClick={openAnchor}>
            <PlusOutlined className={classes.addBoxIcon} />
          </Box>
          <CardContent
            className={classes.questionBox}
            onClick={setSelectedQuestion}
            style={isActive() ? activeStyle(iconHover) : inActiveStyle(iconHover)}
          >
            <div className={classes.nameSection}>
              {/* {props.children} */}
              {questionItem.type === '' ? (
                <p>
                  This form is currently empty. You can add questions, notes, prompts, or other fields by clicking on
                  the + sign below.
                </p>
              ) : (
                <Box className={classes.iconBorderRight}>
                  <img src={getImageByType(questionItem.type)} height="auto" width="40" />
                </Box>
              )}
              <div className={classes.question}>
                <Typography variant="h6">{questionItem.label['EN'] || 'New Question'}</Typography>
                <Typography variant="overline">{questionItem.hint.EN || 'question hint'}</Typography>
              </div>
            </div>
            <Typography variant="h5" style={{ color: 'gray', fontWeight: 700 }}>
              {iconHover.text}
            </Typography>
            <div className={classes.iconSection}>
              {questionItem.type !== '' && (
                <>
                  <Tooltip title="Settings" placement="right" arrow>
                    <span
                      onMouseEnter={() => setIconHover({ bg: '#BDBDBD99', text: 'Settings' })}
                      onMouseLeave={() => setIconHover({ bg: 'white', text: '' })}
                    >
                      <SettingOutlined className={classes.settingsIcon} />
                    </span>
                  </Tooltip>

                  <Tooltip title="Delete" placement="right" arrow>
                    <span
                      onMouseEnter={() => setIconHover({ bg: '#EF535099', text: 'Delete Question' })}
                      onMouseLeave={() => setIconHover({ bg: 'white', text: '' })}
                    >
                      <DeleteOutlined className={classes.deleteIcon} onClick={onDelete} />
                    </span>
                  </Tooltip>

                  <Tooltip title="Duplicate" placement="right" arrow>
                    <span
                      onMouseEnter={() => setIconHover({ bg: '#4CAF5099', text: 'Duplicate Question' })}
                      onMouseLeave={() => setIconHover({ bg: 'white', text: '' })}
                    >
                      <CopyOutlined
                        className={classes.copyIcon}
                        onClick={(event: React.SyntheticEvent) => duplicateQuestion(event, questionItem.id)}
                      />
                    </span>
                  </Tooltip>
                  <Tooltip title="Add" placement="right" arrow>
                    <span
                      onMouseEnter={() => setIconHover({ bg: 'lightblue', text: 'Add to Question Bank' })}
                      onMouseLeave={() => setIconHover({ bg: 'white', text: '' })}
                    >
                      <FileAddOutlined className={classes.quesBankIcon} onClick={addToQuestionBank} />
                    </span>
                  </Tooltip>
                </>
              )}
            </div>
          </CardContent>
        </Box>

        <QuestionAddTool
          open={anchorVisibility}
          anchorEL={anchor}
          toggleAnchor={toggleAnchor}
          currentQustion={questionItem}
        />
        {isActive() ? <QuestionConfig /> : null}
      </Box>
    </Slide>
  );
}
