import * as React from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  FormControlLabel,
  FormControl,
  Grid,
} from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { Switch } from 'antd';
import { CaretDownOutlined, DeleteOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons';
import Style from '../../features/FormDesigner/Questions/Style';
import { Button, Form, Input, Select } from 'antd';
import { Option } from 'antd/lib/mentions';
import MatrixStyle from './Style';
import axiosCategoryServices from '../../helpers/axiosCategoryService';
import { _deserailize } from '../../features/FormDesigner/Settings/Options/utils';
import { notification } from 'antd';
import { fetchCategoryURL, fetchItemURL } from '../../configs/ServerUrl';

function Row(props: { key: number; row: any; cols: any; deleteRow: any; rowValueChange: any }) {
  const { row, cols, deleteRow, rowValueChange } = props;
  const [open, setOpen] = React.useState(false);

  const classes = Style();
  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell style={{ border: '1px solid #f7f7f7' }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
          {row.label}
          <DeleteOutlined className={classes.deleteIcon} onClick={() => deleteRow(row)} />
        </TableCell>
        {cols.map((col: any, key: any) => (
          <TableCell style={{ border: '1px solid #f7f7f7' }} key={key} component="th" scope="row">
            {cols[key].choices.map((choice: any) => (
              <>
                <FormControl>
                  <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                    <FormControlLabel disabled value="female" control={<Radio />} label={choice.label} />
                  </RadioGroup>
                </FormControl>
              </>
            ))}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div"></Typography>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <div>
                    <Form {...layout} form={form} name="control-hooks">
                      <Form.Item name="label" label="Label" rules={[{ required: true }]}>
                        <Input onChange={(e: any) => rowValueChange(row.id, e.target.value, 'label')} />
                      </Form.Item>
                      <Form.Item name="dataColumnPrefix" label="Data Column Prefix" rules={[{ required: false }]}>
                        <Input onChange={(e: any) => rowValueChange(row.id, e.target.value, 'dataColumnPrefix')} />
                      </Form.Item>
                    </Form>
                  </div>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function InnerRow({
  column,
  setSelectedCategory,
  fetchItems,
  columnKey,
  question,
  matrixClasses,
  layout,
  onResponseTypeChange,
  columnValueChange,
  optionValueChange,
  selectedCategory,
  fetchItemNumber,
  onSelectTypeChange,
  setCategoryId,
  category,
  loadings,
  removeOption,
  addOption,
  items,
  onDefaultTypeChange,
  clearOptions,
}: any) {
  const [switchValue, setSwitchValue] = React.useState(column.isDefault);
  React.useEffect(() => {
    if (!column.isDefault) {
      setSwitchValue(column.isDefault);
      setSelectedCategory(column.category);
      fetchItems(column.category?.id);
      clearOptions(column.id);
    }
  }, []);
  const onSwitchChange = (e: boolean) => {
    setSwitchValue(e);
  };

  return (
    <TableRow key={columnKey} style={{ display: `${column.expand}` }}>
      <TableCell
        className={column.background == 'selectedTh' ? matrixClasses.selectedTh : ''}
        colSpan={question.matrix?.columns.length + 1}
      >
        <Form {...layout} name="control-hooks">
          <Form.Item label="Response Type" rules={[{ required: false }]}>
            <Select
              onChange={(value: any) => onResponseTypeChange(value, column.id)}
              allowClear
              defaultValue={responseTypes[0]}
            >
              {responseTypes.map((value: string, key: any) => (
                <Option key={key} value={value}>
                  {value}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Label" rules={[{ required: false }]}>
            <Input value={column.label} onChange={(e: any) => columnValueChange(column.id, e.target.value, 'label')} />
          </Form.Item>
          <Form.Item label="Data Column Prefix" rules={[{ required: false }]}>
            <Input
              value={column.dataColumnPrefix}
              onChange={(e: any) => columnValueChange(column.id, e.target.value, 'dataColumnPrefix')}
            />
          </Form.Item>

          {column.isChoice && (
            <>
              <Form.Item label="Choose type" rules={[{ required: false }]}>
                <Switch
                  checkedChildren="Default"
                  unCheckedChildren="Master"
                  defaultChecked={switchValue}
                  onChange={(e: boolean) => {
                    onSwitchChange(e);
                    console.log('valeu', e);
                    onDefaultTypeChange(e, column.id);
                    if (e === false) {
                      clearOptions(column.id);
                    } else if (e === true) {
                      onSelectTypeChange(false, column.id);
                      onDefaultTypeChange(true, column.id);
                    }
                  }}
                />
              </Form.Item>
              {!switchValue && (
                <Form.Item label="Select Category" rules={[{ required: false }]}>
                  <Select
                    defaultValue={
                      column.category && column?.isSelect ? column.category.categoryValue || 'Select' : 'Select'
                    }
                    onChange={(value: any) => {
                      fetchItemNumber.current = 1;
                      console.log('value\n\n\n', value, column.id);
                      if (value) {
                        fetchItems(value);
                        onSelectTypeChange(true, column.id);
                        console.log('cloneColumns: ', column);
                        setSelectedCategory(value);

                        const item = category.filter((c: any) => c.id === value);
                        console.log('item', item);
                        setCategoryId(item[0], column.id);
                      } else {
                        onSelectTypeChange(false, column.id);
                      }
                    }}
                    allowClear
                    loading={loadings}
                  >
                    {category.map((value: any, key: any) => (
                      <Option key={key} value={value.id}>
                        {value.categoryValue}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}

              <div className={matrixClasses.groupField}>
                {console.log('col', column)}
                {switchValue &&
                  column.choices &&
                  column.choices.map((data: any, i: number) => {
                    return (
                      <div className={matrixClasses.groupFieldInline} key={i}>
                        <Form.Item label="Option:" rules={[{ required: true }]}>
                          <Input
                            value={data.label}
                            onChange={(e: any) => optionValueChange(data.id, columnKey, e.target.value, 'label')}
                          />
                        </Form.Item>
                        <Form.Item
                          className={matrixClasses.gapBetweenGroupField}
                          label="Value:"
                          rules={[{ required: true }]}
                        >
                          <Input
                            value={data.dataColumnName}
                            onChange={(e: any) =>
                              optionValueChange(data.id, columnKey, e.target.value, 'dataColumnName')
                            }
                          />
                        </Form.Item>
                        <DeleteOutlined
                          className={matrixClasses.deleteForeverIcon}
                          onClick={() => removeOption(data.id, columnKey)}
                        />
                      </div>
                    );
                  })}
              </div>

              <div className={matrixClasses.addIconCenterBtn}>
                {switchValue && column.choices && (
                  <Button type="dashed" className={matrixClasses.addIconCenter} onClick={() => addOption(column.id)}>
                    <PlusOutlined> Click to add another response</PlusOutlined>
                  </Button>
                )}
              </div>

              {column.isSelect && !switchValue && (
                <Grid item container justifyContent="center" spacing={2}>
                  {items && items.length === 0 && <div>No items available under this category</div>}
                  {items &&
                    items.length > 0 &&
                    items.map((data: any, i: number) =>
                      data.itemName.map((name: any) => {
                        return (
                          <Grid item container key={i} spacing={2}>
                            <Grid container item xs={6}>
                              <Grid item xs={4}>
                                {`Option (${name.language.name}):`}
                              </Grid>
                              <Grid item xs={8}>
                                <Input disabled name="option" value={name.title} />
                              </Grid>
                            </Grid>
                            <Grid container item xs={6}>
                              <Grid item xs={4}>
                                Value
                              </Grid>
                              <Grid item xs={8}>
                                <Input disabled name="value" value={data.itemValue} />
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      }),
                    )}
                  <div className={matrixClasses.addIconCenterBtn}>
                    {items.length > 0 && (
                      <Button
                        type="dashed"
                        className={matrixClasses.addIconCenter}
                        onClick={() => {
                          fetchItemNumber.current = fetchItemNumber.current + 1;
                          fetchItems(selectedCategory);
                        }}
                      >
                        <CaretDownOutlined /> See more
                      </Button>
                    )}
                  </div>
                </Grid>
              )}
            </>
          )}
        </Form>
      </TableCell>
    </TableRow>
  );
}
const responseTypes: string[] = ['Text', 'Number', 'Select One', 'Select Many'];

export default function MatrixTable(props: {
  question: any;
  deleteColumn: any;
  deleteRow: any;
  columnValueChange: any;
  showHideColumnSetting: any;
  removeOption: any;
  addOption: any;
  optionValueChange: any;
  onResponseTypeChange: any;
  onRowValueChange: any;
  onSelectTypeChange: any;
  setCategoryId: any;
  onDefaultTypeChange: any;
  clearOptions: any;
}) {
  const {
    question,
    deleteColumn,
    deleteRow,
    columnValueChange,
    showHideColumnSetting,
    removeOption,
    addOption,
    optionValueChange,
    onResponseTypeChange,
    onRowValueChange,
    onSelectTypeChange,
    setCategoryId,
    onDefaultTypeChange,
    clearOptions,
  } = props;

  const classes = Style();
  const matrixClasses = MatrixStyle();
  // const [show, setShow] = React.useState('none');
  // const [id, setId] = React.useState(0);
  // const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const [loadings, setLoadings] = React.useState<boolean>();
  const fetchItemNumber = React.useRef<any>(1);
  const [category, setCategory] = React.useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = React.useState<any>();
  const [items, setItems] = React.useState<any[]>([]);
  // const [value, setValue] = React.useState<any>(true);

  // const onSetting = (id: any) => {
  //   if (show == 'none') {
  //     setShow('');
  //   } else {
  //     setShow('none');
  //   }
  // };

  const getDefaultCategory = (categories: any) => {
    const temp_categories = categories;
    temp_categories.map((category: any) => {
      const cat_names = category.categoryName;
      if (typeof cat_names !== 'string') {
        const cat_name = cat_names?.filter((cat: any) => cat.language.isDefault);
        if (cat_name[0]?.title !== '') category.label = cat_name[0]?.title;
      }
      category.value = category.id;
      if (category.parent && category.parent !== 'string') {
        category.parent = category.parent.categoryName[0].title;
      }
    });
    return temp_categories;
  };

  const fetchCategory = async () => {
    const payload = {
      data: {
        type: 'categories',
        attributes: {
          masterdataCategory: {},
          searchString: '',
          updateFlag: false,
        },
      },
    };
    try {
      setLoadings(true);
      const response = await axiosCategoryServices.post(`${fetchCategoryURL}`, payload);
      const categories = await _deserailize(response.data);
      setCategory(getDefaultCategory(categories));
      setLoadings(false);

      notification['success']({
        message: 'Success',
        description: 'Category Fetched !',
      });
    } catch (ex) {
      notification['error']({
        message: 'Error',
        description: 'Cant fetch category',
      });
    }
  };

  React.useEffect(() => {
    console.log('ques', props.question.options);
    fetchCategory();
  }, []);

  const fetchItems = async (value: any) => {
    const payload = {
      data: {
        type: 'Items',
        attributes: {
          masterData: {
            categoryId: value,
          },
          searchString: '',
          updateFlag: false,
          pageSize: fetchItemNumber.current,
        },
      },
    };
    try {
      const response = await axiosCategoryServices.post(`${fetchItemURL}`, payload);
      const items = await _deserailize(response.data);
      setItems([]);
      console.log('items: ', items);
      setItems(items);
      notification['success']({
        message: 'Success',
        description: 'Items Fetched !',
      });
    } catch (ex) {
      notification['error']({
        message: 'Error',
        description: 'Cant fetch items',
      });
    }
  };

  return (
    <TableContainer>
      <Table aria-label="collapsible table" style={{ border: '1px solid #f7f7f7' }}>
        <TableHead>
          <TableRow>
            <TableCell />
            {question.matrix?.columns.map((column: any, key: any) => (
              <TableCell className={column.background == 'selectedTh' ? matrixClasses.selectedTh : ''} key={key}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {column.label}
                  <div>
                    <SettingOutlined
                      className={classes.settingsIcon}
                      onClick={() => showHideColumnSetting(column.id, column.expand)}
                    />
                    <DeleteOutlined className={classes.deleteIcon} onClick={() => deleteColumn(column)} />
                  </div>
                </div>
              </TableCell>
            ))}
          </TableRow>
          {question.matrix?.columns.map((column: any, columnKey: any) => (
            <InnerRow
              key={columnKey + 1}
              column={column}
              setSelectedCategory={setSelectedCategory}
              fetchItems={fetchItems}
              columnKey={columnKey}
              question={question}
              matrixClasses={matrixClasses}
              layout={layout}
              onResponseTypeChange={onResponseTypeChange}
              columnValueChange={columnValueChange}
              optionValueChange={optionValueChange}
              selectedCategory={selectedCategory}
              fetchItemNumber={fetchItemNumber}
              onSelectTypeChange={onSelectTypeChange}
              onDefaultTypeChange={onDefaultTypeChange}
              setCategoryId={setCategoryId}
              category={category}
              loadings={loadings}
              removeOption={removeOption}
              addOption={addOption}
              items={items}
              clearOptions={clearOptions}
            />
          ))}
        </TableHead>
        <TableBody>
          {question.matrix.rows.map((row: any, key: any) => (
            <Row
              key={key}
              row={row}
              cols={question.matrix?.columns}
              deleteRow={deleteRow}
              rowValueChange={onRowValueChange}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
